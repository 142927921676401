const data = [
  {
    msg: "Chinmaya Vidyalaya was not only my school but it has also been my source of inspiration and strength throughout my childhood and adolescence. I have been very fortunate to have grown up under the watchful eyes and caring hands of teachers who were instrumental in shaping my personality. Physical fitness and music are the two pillars of my well being today and both of them are precious gifts received from my gurus at Chinmaya Vidyalaya. Both these activities have played a pivotal role in defining the person that I have become today. Even after more than 2 decades of leaving school exercise and music are an inseparable part of my life. I feel blessed to have been part of the Chinmaya experience.",
    name: "Anand HS",
    batch: "1994",
  },
  {
    msg: "I have been lucky to have studied in a culturally rich school as Chinmaya Vidyalaya. My alma mater not only taught me the importance of our cultural heritage, it also taught me humility and kindness. During any adverse situations, our school motto (keep smiling) has helped me go on. Special thanks to all my teachers for making me the confident woman I am today.",
    name: "Sreela",
    batch: "1998",
  },
  {
    msg: "Chinmaya Vidyalaya has taught me all that I want to be in life. With the blessing of Pujya Swami Chinmayananda ji I believe I have become an individual who gives equal weightage to both morality, integrity and worldly possessions as well. Thank you Chinmaya for making me a balanced individual in life, whose roots are grounded.",
    name: "Riddhi Gulati",
    batch: "2007",
  },
  {
    msg: "My school not only equipped me with best education, but also with values of spirituality and kindness. We have been taught here the lessons from Bhagavat Gita, which helped us to perceive things differently from others. Teacher's dedication always reminded us that service to the society must be one's goal.",
    name: "Srishti Dhoundiyal",
    batch: "2015",
  },

  {
    msg: "Chinmaya Vidyalaya has been a strong and memorable pillar in shaping myself. My teachers, in their support and appreciation for perhaps the really small gestures of mine, the amount of strength I derived from being a part of the students' council of Chinmaya , in the wisdom and responsibilities given have helped me in taking individual stances and being my own guide when I look back today. Chinmaya has made me realize the importance of all these gestures we title as 'little' like greeting teachers, in just holding up a smile etc.. And I realise these acknowledgements from both the teaching and non teaching staff of Chinmaya, have really helped me in holding who I am!",
    name: "Sreelekshmi Namboothiri",
    batch: "2015",
  },

  {
    msg: "My time at the school was a great learning experience (besides the academics). The impact of regular Gita chanting, morning assembly and school prayers is immense. Today, I'm proud of the school that taught me the values of friendship, honesty and respect.",
    name: "Akshat Aayush",
    batch: "2016",
  },
];

export default data;
